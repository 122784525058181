import React, {useState} from "react";
import {Button,Container,Row,Col} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

const CardItem = ({cardData,index})=>{

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    

    /*async function readDetails (){
        return Buffer.from(await clientIPFS.cat("http://194.163.151.51:8092/ipfs/"+cardData.details)).toString('utf8');
    }*/

    return(
        <div key={index} className="col-md-3 col-sm-6">
            <article className="team-item">
                <figure className="team-img">
                    <img src={"http://194.163.151.51:8092/ipfs/"+cardData.template} alt=""/>
                    <div className="social-links">
                        <ul>
                        <li>
                        
                            <a href="#"  aria-label="Center Align" onClick={handleShow}>
                                <i className="icon icon-zoom-in" aria-hidden="true"></i>
                            </a>
                        </li>
                        </ul>
                    </div>
                </figure>
                <div className="team-content">
                    <h4>{cardData.templateName}</h4>
                    <span>@{cardData.templateAuthor}</span>
                </div>
            </article>
            {/*<!-- Modal -->*/}
            <Modal
                show={show}
                onHide={handleClose}
                keyboard={false}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title >{cardData.templateName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col>
                            <img src={"http://194.163.151.51:8092/ipfs/"+cardData.page1} alt="" width={"100%"}/>
                            </Col>
                            <Col>
                            <img src={"http://194.163.151.51:8092/ipfs/"+cardData.page2} alt="" width={"100%"}/>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} style={{backgroundColor:"#D1711F"}}>
                        Close
                    </Button>
                   {/*<Button variant="primary">Understood</Button>*/}
                </Modal.Footer>
            </Modal>
        </div>
        
    );
}

export default CardItem;