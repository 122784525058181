import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

const OffCanvasWrap = ()=>{
    const {t} = useTranslation(['off_canvas','common']);

    return (
        <Fragment>
            <div className="offcanvas-wrap text-center">
                    <div className="offcanvas-inner">
                    <div className="offcanvas-close">
                        <i aria-hidden="true" className="fas fa-times"></i>
                    </div>
                    <div className="offcanvas-content">
                        <div className="widget offcanvas-info">
                            <a href="index.html">
                                <img src="assets/images/site-logo1.png" alt=""/>
                            </a>
                            <p>
                            {t("CANVASHEAD")}. 
                            </p>
                        </div>
                        <div className="widget offcanvas-content">
                            <h3 className="widget-title">{t("COMMON_CONTACTUS",{ns:"common"})}</h3>
                            <ul className="widget-content-contact">
                                <li>CM/Yaounde, Rond Point LongKak</li>
                                <li>Mobile : +237 (696)-871-880</li>
                                <li>Mobile : +237 (675)-888-025</li>
                                <li>Email : dservice237@gmail.com</li>
                            </ul>
                            <div className="social-links">
                                <ul>
                                <li>
                                    <a href="https://www.facebook.com/dservice.devent" target="_blank" rel="noopener">
                                        <i className="fab fa-facebook-f" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/ddevent" target="_blank" rel="noopener">
                                    <i className="fab fa-linkedin" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://instagram.com/dd.even" target="_blank" rel="noopener">
                                        <i className="fab fa-instagram" aria-hidden="true"></i>
                                    </a>
                                </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="overlay"></div>
                </div>
        </Fragment>
    );
}

export default OffCanvasWrap;