import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import InteractionVM from "../view_model/interaction_vm";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import { Helmet } from 'react-helmet';

const Header = ()=>{
    const navBarState = InteractionVM((state)=>state.navBar);
    const defaultLanguage = InteractionVM((state)=>state.defaultLanguage);
    const navBarAction = InteractionVM((state)=>state.navigateAction);
    const changeLanguage = InteractionVM((state)=>state.changLanguage);
    
    useEffect(()=>{
        i18n.changeLanguage(defaultLanguage);
    },[]);

    const {t} = useTranslation(['menu']);

    return (
        <Fragment>
            <header id="masthead" className="site-header site-header-transparent">
                {/*header html start*/}
                <div className="top-header">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-8 d-none d-lg-block"> 
                            <div className="header-contact-info">
                            <ul>
                                <li>
                                    <a href="tel:+237675888025"><i className="fas fa-phone-alt"></i> +237 (696) 8718 80 / +237 (675) 8880 25</a>
                                </li>
                                <li>
                                    <a href="mailto:dservice237@gmail.com"><i className="fas fa-envelope"></i> dservice237@gmail.com</a>
                                </li>
                                <li>
                                    <i className="fas fa-map-marker-alt"></i> CM/Yde/Rond point Longkak
                                </li>
                                
                            </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 d-flex justify-content-lg-end">
                            <div className="header-social social-links">
                            <div id="en" onClick={()=>{changeLanguage("en");window.location.reload(false); }} key={"en"}>
                                <input type="radio" name="flag" value="en" id="regular" checked={defaultLanguage==="en"} onChange={(v)=>{}}/> <img src="assets/images/flag/en.png" alt="numerique_ticket_aboutus" style={{width:"20px",backgroundColor:"white",borderRadius: "30px"}}/>
                            </div>   
                            <div id="fr" style={{marginLeft:"10px",marginRight:"30px"}} key={"fr"} onClick={()=>{changeLanguage("fr");window.location.reload(false); }}>
                                <input type="radio" name="flag" value="fr" id="regular" checked={defaultLanguage==="fr"} onChange={(v)=>{}} /> <img src="assets/images/flag/fr.png" alt="numerique_ticket_aboutus" style={{width:"20px",backgroundColor:"white",borderRadius: "30px"}}/>
                            </div>
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/dservice.devent" target="_blank" rel="noreferrer">
                                        <i className="fab fa-facebook-f" aria-hidden="true"></i>
                                    </a>
                                </li>
                                {/*<li>
                                    <a href="https://www.twitter.com" target="_blank" rel="noreferrer">
                                        <i className="fab fa-twitter" aria-hidden="true"></i>
                                    </a>
                                </li>*/}
                                {/*<li>
                                    <a href="https://www.youtube.com" target="_blank" rel="noreferrer">
                                        <i className="fab fa-youtube" aria-hidden="true"></i>
                                    </a>
                                </li>*/}
                                <li>
                                    <a href="https://instagram.com/dd.even" target="_blank" rel="noreferrer">
                                        <i className="fab fa-instagram" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/ddevent" target="_blank" rel="noreferrer">
                                        <i className="fab fa-linkedin" aria-hidden="true"></i>
                                    </a>
                                </li>
                            </ul>
                            </div>
                            {/*<div className="header-search-icon">
                            <button className="search-icon">
                                <i className="fas fa-search"></i>
                            </button>
                            </div>*/}
                            <div className="offcanvas-icon">
                            <a href="#">
                                <i aria-hidden="true" className="icon icon-burger-menu"></i>
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div className="bottom-header">
                <div className="container">
                    <div className="bottom-header-inner d-flex justify-content-between align-items-center">
                        <div className="site-identity">
                            <h1 className="site-title">
                            <a href="/" className="white-logo">
                                <img src="assets/images/site-logo1.png" alt="Logo" width={"70%"}/>
                            </a>
                            <Link to="/" className="black-logo">
                                <img src="assets/images/site-logo.png" alt="Logo" width={"35%"}/>
                            </Link>
                            </h1>
                        </div>
                        <div className="main-navigation"> 
                            <nav id="navigation" className="navigation d-none d-lg-inline-block">
                            <ul>
                                <li className={navBarState[0]?"current-menu-item":""} onClick={()=>{navBarAction([true,false,false,false])}}>
                                    <Link to="/">{t("HOME")}</Link>
                                </li>
                                <li className={navBarState[1]?"current-menu-item":""} onClick={()=>{navBarAction([false,true,false,false,false])}}>
                                    <Link to="/event_tickets">{t("EVENT_TICKET")}</Link>
                        </li> 
                                {/*<li className={navBarState[2]?"current-menu-item":""} onClick={()=>{navBarAction([false,false,true,false,false])}}>
                                    <Link to="/weddind_cards">{t("WEDDING_CARDS")}</Link>
                    </li>*/}
                                <li className={navBarState[3]?"current-menu-item":""} onClick={()=>{navBarAction([false,false,false,true,false])}}>
                                    <Link to="/about_us">{t("ABOUT_US")}</Link>
                                </li>
                                {/*<li className="menu-item-has-children">
                                    <a to="#">Shop</a>
                                    <ul>
                                        <li>
                                        <a to="product-right.html">Shop Archive</a>
                                        </li>
                                        <li>
                                        <a to="product-detail.html">Shop Single</a>
                                        </li>
                                        <li>
                                        <a to="product-cart.html">Shop Cart</a>
                                        </li>
                                        <li>
                                        <a to="product-checkout.html">Shop Checkout</a>
                                        </li>
                                    </ul>
                                </li>*/}
                                <li className={navBarState[4]?"current-menu-item":""} onClick={()=>{navBarAction([false,false,false,false,true])}}>
                                    <Link to="/contact_us">{t("CONTACT_US")}</Link>
                                </li>
                            </ul>
                            </nav>
                        </div>
                        <div className="mobile-menu-container d-lg-none"></div>
                    </div>
                </div>
                </div>
            </header>
        </Fragment>
    );
}

export default Header;