

import { create } from 'ipfs-http-client';
import {cardService} from '../api/card';


export const IPFS = {
    sendCardToIpfsAPI,
    getCardFromIpdfAPI
};

//127.0.0.1
async function sendCardToIpfsAPI(cartDetail) {
    const clientIPFS = create(new URL('https://devent.my-dservice.com:5001/api/v0'));

    if(clientIPFS){
        
        try{
            console.log("clientIPFS connection");
            let design1BackgroundIPFS = [];
            cartDetail["design1Background"].forEach(async element => {
                design1BackgroundIPFS.push((await clientIPFS.add(element)).path);
            });
            let design1DecoIPFS = [];
            cartDetail["design1Deco"].forEach(async element => {
                design1DecoIPFS.push((await clientIPFS.add(element)).path);
            });
            let design2BackgroundIPFS = [];
            cartDetail["design2Background"].forEach(async element => {
                design2BackgroundIPFS.push((await clientIPFS.add(element)).path);
            });
            let design2DecoIPFS = [];
            cartDetail["design2Deco"].forEach(async element => {
                design2DecoIPFS.push((await clientIPFS.add(element)).path);
            });
            let design3BackgroundIPFS = [];
            cartDetail["design3Background"].forEach(async element => {
                design3BackgroundIPFS.push((await clientIPFS.add(element)).path);
            });
            let design3DecoIPFS = [];
            cartDetail["design3Deco"].forEach(async element => {
                design3DecoIPFS.push((await clientIPFS.add(element)).path);
            });
            let data = {
                "templateName":cartDetail["details"].templateName,
                "templateAuthor":cartDetail["details"].author,
                "page1":(await clientIPFS.add(cartDetail["page1"][0])).path,
                "page2":(await clientIPFS.add(cartDetail["page2"][0])).path,
                "template":(await clientIPFS.add(cartDetail["template"][0])).path,
                "details":(await clientIPFS.add(JSON.stringify(cartDetail["details"]))).path,
                "design1Background":design1BackgroundIPFS,
                "design1Deco":design1DecoIPFS,
                "design2Background":design2BackgroundIPFS,
                "design2Deco":design2DecoIPFS,
                "design3Background":design3BackgroundIPFS,
                "design3Deco":design3DecoIPFS
            };
            
            cardService.saveCardApi(data);

            console.log("------------- CID path--------------");
            console.log(data);
            return data.template;
        }catch(error){
            console.log("------------- error ------------");
            console.log(error)
            return undefined;
        }
    }
}

function getCardFromIpdfAPI(){  
    //127.0.0.1
    const clientIPFS = create(new URL('https://devent.my-dservice.com:5001/api/v0'));
    if(clientIPFS){
        console.log("getCardFromIpdf");
    }
    return [];
}
