import React from "react";
import { Route, Routes } from "react-router-dom";
import WelcomePage from "./page/welcome_page";
import WeddingCards from "./page/wedding_card";
import EventTicket from "./page/event_ticket";
import ContactUs from "./page/contact_us";
import AboutUs from "./page/about_us";
import Header from "./components/header";
import Footer from "./components/footer";
import './i18n'
import MainTicketEvent from "./page/main_ticket_event";
import { Helmet } from 'react-helmet';

function App() {

  return (
    <>
      {/*<Loader/>*/}
     
      <div id="page" className="full-page">
        <Header />
        <Routes>
          <Route path="/" element={<WelcomePage />} />
          <Route path="/weddind_cards" element={<WeddingCards />} />
          <Route path="/event_tickets" element={<MainTicketEvent />} />
          <Route path="/contact_us" element={<ContactUs />} />
          <Route path="/about_us" element={<AboutUs />} />
        </Routes>
        <Footer />
      </div>
    </>
  );
}

export default App;
