import {create} from "zustand";
import {persist,createJSONStorage} from "zustand/middleware";
import {IPFS} from "../service/ipfs/ipfs";
import {cardService} from '../service/api/card';

const CardsVM = create(
    persist(
        (set,get) => (
            {
                listCards: [],
                tempCars:[],
                newCardSave: {},
                loader: false,
                saveCard: async (cartDetail) => {
                    set({newCardSave: await IPFS.sendCardToIpfsAPI(cartDetail)});
                },
                fetchListCards: async ()=>{
                    set({listCards: await cardService.getAllCards()});
                    set({tempCars: get().listCards});
                },
                filterCards:(condition)=>{
                    set({listCards: get().tempCars});
                    var temp = get().listCards.filter((e)=>{
                        return e.templateName.includes(condition);
                    });
                    set({listCards: temp});
                },
                toogleLoader: async (valu)=>{
                    set({loader: valu});
                },
            }
        ),
        {
            name: 'cards-storage', // unique name
            storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
        }
    )
)

export default CardsVM;