export const environment  = {
    isRomote: false,
    http: "http",
    localIp: "localhost",
    remoteIp: "devent.my-dservice.com",
    port: 8197,
    ipfsPortGet: 8092,
    ipfsPortSend: 5002
};

export var baseUrl = environment.http+"://" + (environment.isRomote ? environment.remoteIp : environment.localIp) ;
console.log("------ base url --- "+ baseUrl);

///-------------------- remote URL for IPFS ---------------------
export var ipfsUrl = {
    IPFS_GET_IMAGE : environment.http+"://"+environment.isRomote ? environment.remoteIp : environment.localIp+":"+environment.ipfsPortGet+"/ipfs/",
    IPFS_SEND_IMAGE : baseUrl+":"
}

///-------------------- remote URL for API call -----------------
export var serviceApi = {
    SAVE_CARD:  baseUrl+":"+environment.port+"/api/v1/de/card",
    GET_ALL_CARD_API : baseUrl+":"+environment.port+"/api/v1/de/card",
    SEND_EMAIL : baseUrl+":"+environment.port+"/api/v1/mail",
    GET_ALL_TAG : baseUrl+ "/tag"
}

