import React, { Fragment, useEffect } from "react";
//import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import InteractionVM from "../view_model/interaction_vm";
import { useTranslation } from "react-i18next";

import { loadFn } from '../script/custom'

const MainTicketEvent = ()=>{ 
    const navBarState = InteractionVM((state)=>state.navBar);
    const navBarAction = InteractionVM((state)=>state.navigateAction);
    const {t} = useTranslation(['welcome','common']);

    /*useEffect(
        ()=>{
            // Code to run on component mount
            console.log('Component has mounted');

            // Optional: return a cleanup function if needed
            return () => { 
            console.log('Component will unmount');
            }; 
        },[]
    );*/

    useEffect(() => {
        loadFn()
    }, [])

    return (
        <Fragment>
            {/* <Helmet>
                <script src="/assets/js/custom.js" async></script>
            </Helmet> */}
            <main id="content" className="site-main">
                {/*main ticket event banner*/}
                <section className="home-banner">
                <div className="container">
                    <div className="home-banner-inner">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="banner-content">
                                    <div className="sub-title">{t('HEADER_TITLE1')}</div>
                                    <h2 className="banner-title">{t('HEADER_TITLE2')}</h2>
                                    <div className="banner-text">
                                        <p>{t('HEADER_P1')} <b>{t('COMMON_WEDDING',{ns:"common"})}</b> - <b>{t('COMMON_BIRTHDAY',{ns:"common"})}</b> {t('HEADER_P2')} <b>{t('COMMON_EVENT',{ns:"common"})}</b> {t('HEADER_P3')}</p>
                                    </div>
                                    <div className="banner-button">
                                        <a href="assets/apk/devent.apk" className="button-round" target="_blank">{t('COMMON_DOWNLOAD_M',{ns:"common"})}</a>
                                        <Link to="about_us"  className={navBarState[3]?"current-menu-item outline-round":"outline-round"} onClick={()=>{navBarAction([false,false,false,true,false])}}>{t('COMMON_LEARN_MORE_M',{ns:"common"})}</Link>                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                            <figure className="banner-image">
                                <img src="assets/images/img1.png" alt=""/>
                            </figure>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overlay"></div>
                </section>
            </main>
        </Fragment>
    );

}

export default MainTicketEvent;