import {create} from "zustand";
import {persist,createJSONStorage} from "zustand/middleware";
import i18n from "../i18n";

const InteractionVM = create(
    persist(
        (set) => (
            {
                defaultLanguage: sessionStorage.getItem("devent_local_language"),
                navBar: [true,false,false,false,false],
                navigateAction: async (newNavBar) => {
                    set({navBar: newNavBar});
                },
                changLanguage: (language)=>{
                    i18n.changeLanguage(sessionStorage.getItem("devent_local_language"));
                    sessionStorage.setItem("devent_local_language",language);
                    set({defaultLanguage : language});
                }
            }
        ),
        {
            name: 'interaction-storage', // unique name
            storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
        }
    )
);

export default InteractionVM;