import React, { useEffect, useState } from "react";
import {Button,Container,Row,Col} from 'react-bootstrap';
import {IPFS} from "../service/ipfs/ipfs";
import CardsVM from "../view_model/cards_vm";
import Modal from 'react-bootstrap/Modal';

export const FormWeddingCardModal = ({show,handleClose})=>{
    const loader = CardsVM((state)=>state.loader);
    const toogleLoader = CardsVM((state)=>state.toogleLoader);
    const fetchListCards = CardsVM((state)=>state.fetchListCards);
    const [filePath,setFilePath] = useState({});
    const [fileUrl,setFileUrl]  =useState(``);

    useEffect(()=>{
        toogleLoader(false);
    },[filePath]); 

    const sendFile =async () =>{
        toogleLoader(true);
        setFileUrl(await IPFS.sendCardToIpfsAPI(filePath));
        fetchListCards();
        toogleLoader(false);
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title >Save new template</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <form method="get" className="contact-form">
                        <Row>
                            
                                <Col md="6" xs="12">                            
                                    <br/><label>----------Add Details ----------</label><br/><br/>
                                    <p>
                                        <label>Color:</label>
                                        <textarea onChange={(e)=>{
                                        let updateDetail = {...filePath.details,"colors":e.target.value}; 
                                        let updateData = {"details":updateDetail};
                                        setFilePath(filePath=>({...filePath,...updateData}));
                                        }}></textarea>
                                    </p>
                                    <p>
                                        <label>Type card:</label>
                                        <input type="text" onChange={(e)=>{
                                        let updateDetail = {...filePath.details,"typeCard":e.target.value}; 
                                        let updateData = {"details":updateDetail};
                                        setFilePath(filePath=>({...filePath,...updateData}));
                                    }} placeholder="wedding or event" />
                                    </p>
                                    <p>
                                        <label>Author: </label><br/>
                                        <input type="text" onChange={(e)=>{
                                            let updateDetail = {...filePath.details,"author":e.target.value}; 
                                            let updateData = {"details":updateDetail};
                                            setFilePath(filePath=>({...filePath,...updateData}));
                                        }} />
                                    </p>
                                    <p>
                                    <label>template name: </label>
                                    <input type="text" onChange={(e)=>{
                                        let updateDetail = {...filePath.details,"templateName":e.target.value}; 
                                        let updateData = {"details":updateDetail};
                                        setFilePath(filePath=>({...filePath,...updateData}));
                                    }}></input>
                                    </p>
                                    <p>
                                    <label>is premium: </label>
                                    <input type="text" onChange={(e)=>{
                                        let updateDetail = {...filePath.details,"premium":e.target.value}; 
                                        let updateData = {"details":updateDetail};
                                        setFilePath(filePath=>({...filePath,...updateData}));
                                    }} placeholder="yes or no"></input><br/>
                                    </p>
                                    <p>
                                    <label>model: </label><br/>
                                    <input type="text" onChange={(e)=>{
                                        let updateDetail = {...filePath.details,"model":e.target.value}; 
                                        let updateData = {"details":updateDetail};
                                        setFilePath(filePath=>({...filePath,...updateData}));
                                    }} placeholder="1 2 3 ..."></input><br/>
                                    </p>
                                </Col>
                                <Col md="6" xs="12">
                                    <br/><label>---------- Add all images ----------</label><br/>
                                    <br/><label>Page1</label>
                                    <input type="file" name="page1" onChange={(e)=>{
                                        let updateData = {"page1":[e.target.files[0]]};
                                        setFilePath(filePath=>({...filePath,...updateData}));
                                    }}  accept='image/png'/>
                                    <br/><br/><label>Page2</label>
                                    <input type="file" name="page2" onChange={(e)=>{
                                        let updateData = {"page2":[e.target.files[0]]};
                                        setFilePath(filePath=>({...filePath,...updateData}));
                                    }}  accept='image/png'/>
                                    <br/><br/><label>Template</label>
                                    <input type="file" name="template" onChange={(e)=>{
                                        let updateData = {"template":[e.target.files[0]]};
                                        setFilePath(filePath=>({...filePath,...updateData}));
                                    }}  accept='image/png'/>
                                    <br/><br/><label>design1Background</label>
                                    <input type="file" name="design1Background" onChange={(e)=>{
                                        const chosenFiles = Array.prototype.slice.call(e.target.files)
                                        let updateData = {"design1Background":chosenFiles};
                                        setFilePath(filePath=>({...filePath,...updateData}));
                                    }} multiple accept='image/png'/>
                                    <br/><label>design1Deco</label>
                                    <input type="file" name="design1Deco" onChange={(e)=>{
                                        const chosenFiles = Array.prototype.slice.call(e.target.files)
                                        let updateData = {"design1Deco":chosenFiles};
                                        setFilePath(filePath=>({...filePath,...updateData}));
                                    }} multiple accept='image/png'/>
                                    <br/><br/><label>design2Background</label>
                                    <input type="file" name="design2Background" onChange={(e)=>{
                                        const chosenFiles = Array.prototype.slice.call(e.target.files)
                                        let updateData = {"design2Background":chosenFiles};
                                        setFilePath(filePath=>({...filePath,...updateData}));
                                    }} multiple accept='image/png'/>
                                    <br/><label>design2Deco</label>
                                    <input type="file" name="design2Deco" onChange={(e)=>{
                                        const chosenFiles = Array.prototype.slice.call(e.target.files)
                                        let updateData = {"design2Deco":chosenFiles};
                                        setFilePath(filePath=>({...filePath,...updateData}));
                                    }} multiple accept='image/png'/>
                                    <br/><br/><label>design3Background</label>
                                    <input type="file" name="design3Background" onChange={(e)=>{
                                        const chosenFiles = Array.prototype.slice.call(e.target.files)
                                        let updateData = {"design3Background":chosenFiles};
                                        setFilePath(filePath=>({...filePath,...updateData}));
                                    }} multiple accept='image/png'/>
                                    <br/><label>design3Deco</label>
                                    <input type="file" name="design3Deco" onChange={(e)=>{
                                        const chosenFiles = Array.prototype.slice.call(e.target.files)
                                        let updateData = {"design3Deco":chosenFiles};
                                        setFilePath(filePath=>({...filePath,...updateData}));
                                    }} multiple accept='image/png'/>
                                </Col>  
                        
                            
                        </Row>
                    </form>
            
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose} style={{backgroundColor:"#D1711F"}}>
                    Close
                </Button>
                {!loader&&<Button variant="primary" onClick={sendFile} style={{backgroundColor:"#263238"}}>Save Card</Button>}
                {loader&&<div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                </div>}
            </Modal.Footer>
        </Modal>
    );
}