import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import InteractionVM from "../view_model/interaction_vm";
import OffCanvasWrap from "./off_canvas_wrap";
import { useTranslation } from "react-i18next";

const Footer = () => {
    const navBarState = InteractionVM((state)=>state.navBar);
    const navBarAction = InteractionVM((state)=>state.navigateAction);
    const {t} = useTranslation(['footer','menu','common']);

    return (
        <Fragment>
            <footer id="colophon" className="site-footer footer-primary">
                <div className="footer-shape">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                    <path className="elementor-shape-fill" d="M790.5,93.1c-59.3-5.3-116.8-18-192.6-50c-29.6-12.7-76.9-31-100.5-35.9c-23.6-4.9-52.6-7.8-75.5-5.3
                    c-10.2,1.1-22.6,1.4-50.1,7.4c-27.2,6.3-58.2,16.6-79.4,24.7c-41.3,15.9-94.9,21.9-134,22.6C72,58.2,0,25.8,0,25.8V100h1000V65.3
                    c0,0-51.5,19.4-106.2,25.7C839.5,97,814.1,95.2,790.5,93.1z"></path>
                </svg>
                </div>
                <div className="top-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <aside className="widget widget_text">
                            <div className="footer-logo">
                                <a href="/"><img src="assets/images/site-logo1.png" width={"70%"} alt=""/></a>
                            </div>
                            
                            </aside>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <aside className="widget widget_menu">
                            <h3 className="widget-title">{t('QUICK_LINK')}</h3>
                            <ul>
                                <li className={navBarState[1]?"current-menu-item":""} onClick={()=>{navBarAction([false,true,false,false])}}>
                                    <Link to="event_tickets">{t('EVENT_TICKET',{ns:"menu"})}</Link>
                                </li>
                                <li className={navBarState[2]?"current-menu-item":""} onClick={()=>{navBarAction([false,false,true,false,false])}}>
                                    <Link to="weddind_cards">{t('WEDDING_CARDS',{ns:"menu"})}</Link>
                                </li>
                                <li className={navBarState[3]?"current-menu-item":""} onClick={()=>{navBarAction([false,false,false,true,false])}}>
                                    <Link to="about_us">{t('ABOUT_US',{ns:"menu"})}</Link>
                                </li>
                                <li className={navBarState[4]?"current-menu-item":""} onClick={()=>{navBarAction([false,false,false,false,true])}}>
                                    <Link to="contact_us">{t('CONTACT_US',{ns:"menu"})}</Link>
                                </li>
                            </ul>
                            </aside>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <aside className="widget widget_text">
                            <h3 className="widget-title">{t('CONTACT_INF')}</h3>
                            <p>{t('CONTACT_INF')}</p>
                            <div className="textwidget widget-text">
                                <ul>
                                    <li>
                                        <i aria-hidden="true" className="icon icon-map-marker1"></i>
                                        CM/Yaounde, <br/>Rond Point LongKak
                                    </li>
                                    <li>
                                        <a href="tel:+237696871880">
                                        <i aria-hidden="true" className="icon icon-phone1"></i>
                                            +237 (696)-871-880 <br/> +237 (675)-888-025
                                        </a>
                                    </li>
                                    <li>
                                        <a href="mailtop:dservice237@gmail.com">
                                        <i aria-hidden="true" className="icon icon-envelope1"></i>
                                        dservice237@gmail.com
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            </aside>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <aside className="widget widget-download">
                            <h3 className="widget-title">{t('SUP_DOWNLOAD')}</h3>
                            <p>{t('SUP_DOWNLOAD_P')}</p>
                            <div className="widget-btn-wrap">
                                <a href="assets/apk/devent.apk">
                                    <img src="assets/images/img4.png" alt=""/>
                                </a>
                                {/*<a href="#">
                                    <img src="assets/images/img5.png" alt=""/>
                                </a>*/}
                            </div>
                            </aside>
                        </div>
                    </div>
                </div>
                </div>
                <div className="bottom-footer">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="social-links">
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/dservice.devent" target="_blank" rel="noopener">
                                        <i className="fab fa-facebook-f" aria-hidden="true"></i>
                                    </a>
                                </li>
                                {/*<li>
                                    <a href="https://www.twitter.com/" target="_blank">
                                        <i className="fab fa-twitter" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/" target="_blank">
                                        <i className="fab fa-youtube" aria-hidden="true"></i>
                                    </a>
                                </li>*/}
                                <li>
                                    <a href="https://www.linkedin.com/company/ddevent" target="_blank" rel="noopener">
                                        <i className="fab fa-linkedin" aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://instagram.com/dd.even" target="_blank" rel="noopener">
                                        <i className="fab fa-instagram" aria-hidden="true"></i>
                                    </a>
                                </li>
                                
                            </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="copy-right">Copyright &copy; 2023 D.Service. All rights reserved.</div>
                        </div>
                    </div>
                </div>
                </div>
                <div className="overlay"></div>
            </footer>
            <a id="backTotop" href="#" className="to-top-icon">
                <i className="fas fa-chevron-up"></i>
            </a>
            {/*<!-- custom search field html -->*/}
            <div className="header-search-form">
                <div className="container">
                <div className="header-search-container">
                    <form className="search-form" role="search" method="get" >
                        <input type="text" name="s" placeholder="Enter your text..."/>
                    </form>
                    <a href="#" className="search-close">
                        <i className="fas fa-times"></i>
                    </a>
                </div>
                </div>
            </div>
            <OffCanvasWrap />
        </Fragment>
    );
}

export default Footer;