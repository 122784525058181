import React, { Fragment, useEffect } from "react";
//import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import DownloadApp from "../components/download_app";
import InteractionVM from "../view_model/interaction_vm";
import { useTranslation } from "react-i18next";

import { loadFn } from '../script/custom'
/*
    Home page open when app staart
**/
const WelcomePage = ()=>{ 
    const navBarState = InteractionVM((state)=>state.navBar);
    const navBarAction = InteractionVM((state)=>state.navigateAction);
    const {t} = useTranslation(['welcome','common']);
    
    useEffect(() => {
        loadFn()
    }, [])

    return (
        <Fragment>
            {/* <Helmet>
                <script src="/assets/js/custom.js" async></script>
            </Helmet> */}
            <main id="content" className="site-main">
                {/*home banner section html start*/}
                <section className="home-banner">
                <div className="container">
                    <div className="home-banner-inner">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="banner-content">
                                    <div className="sub-title">{t('HEADER_TITLE1')}</div>
                                    <h2 className="banner-title">{t('HEADER_TITLE2')}</h2>
                                    <div className="banner-text">
                                        <p>{t('HEADER_P1')} <b>{t('COMMON_WEDDING',{ns:"common"})}</b> - <b>{t('COMMON_BIRTHDAY',{ns:"common"})}</b> {t('HEADER_P2')} <b>{t('COMMON_EVENT',{ns:"common"})}</b> {t('HEADER_P3')}</p>
                                    </div>
                                    <div className="banner-button">
                                        <a href="assets/apk/devent.apk" className="button-round" target="_blank">{t('COMMON_DOWNLOAD_M',{ns:"common"})}</a>
                                        <Link to="about_us"  className={navBarState[3]?"current-menu-item outline-round":"outline-round"} onClick={()=>{navBarAction([false,false,false,true,false])}}>{t('COMMON_LEARN_MORE_M',{ns:"common"})}</Link>                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                            <figure className="banner-image">
                                <img src="assets/images/img1.png" alt=""/>
                            </figure>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overlay"></div>
                </section>
                {/*home banner section html end*/}
                {/*home process section html start*/}
                <div className="process-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <article className="process-item">
                                <div className="process-head">
                                    <i aria-hidden="true" className="icon icon-users1"></i>
                                    <span className="count-no">01.</span>
                                </div>
                                <div className="process-content">
                                    <h4>
                                        <a href="#">{t('COMMON_WEDDING',{ns:"common"})} {t('COMMON_CARDS',{ns:"common"})}</a>
                                    </h4>
                                    <p>{t('TIP1_YOUHAVE')} <b>{t("COMMON_WEDDING",{ns:"common"})}</b>? {t('TIP1_YOUDONT')} <b>{t('TIP1_TIME')}</b>? {t('TIP1_YOUNEED')} <b>{t('TIP1_PRETTY')} {t('COMMON_CARD',{ns:"common"})}</b> {t('TIP1_DESIGN')}? {t('TIP1_END1')}.<br/> {t('TIP1_END2')}?</p>
                                </div>
                                <div className="progress-footer">
                                    <Link to="contact_us"  className={navBarState[4]?"current-menu-item outline-round":"outline-round"} onClick={()=>{navBarAction([false,false,false,false,true])}}>{t('COMMON_LETSGO',{ns:"common"})}</Link>
                             
                                </div>
                            </article>
                        </div>
                        <div className="col-md-4">
                            <article className="process-item">
                                <div className="process-head">
                                    <i aria-hidden="true" className="icon icon-diamond1"></i>
                                    <span className="count-no">02.</span>
                                </div>
                                <div className="process-content">
                                    <h4>
                                        <a href="#">{t('TIP2_TITLE')}</a>
                                    </h4>
                                    <p>{t('TIP2_P1')} <br/>{t('TIP2_P2')} <b>{t('TIP2_CONCERT')}</b>-<b>{t('TIP2_CONFERENCE')}</b> {t('TIP2_P3')}.</p>
                                </div>
                                <div className="progress-footer">
                                <Link to="contact_us"  className={navBarState[4]?"current-menu-item outline-round":"outline-round"} onClick={()=>{navBarAction([false,false,false,false,true])}}>{t('COMMON_LETSGO',{ns:"common"})}</Link>
                                </div>
                            </article>
                        </div>
                        <div className="col-md-4">
                            <article className="process-item">
                                <div className="process-head">
                                    <i aria-hidden="true" className="icon icon-cakes"></i>
                                    <span className="count-no">03.</span>
                                </div>
                                <div className="process-content">
                                    <h4>
                                        <a href="#">{t('COMMON_BIRTHDAY',{ns:"common"})} {t('COMMON_CARDS',{ns:"common"})}</a>
                                    </h4>
                                    <p>{t('TIP1_YOUHAVE')} <b>{t("COMMON_BIRTHDAY",{ns:"common"})}</b>? {t('TIP1_YOUDONT')} <b>{t('TIP1_TIME')}</b>? {t('TIP1_YOUNEED')} <b>{t('TIP1_PRETTY')} {t('COMMON_CARD',{ns:"common"})}</b> {t('TIP1_DESIGN')}? {t('TIP1_END1')}.<br/> {t('TIP1_END2')}?</p>
                                </div>
                                <div className="progress-footer">
                                    <Link to="contact_us"  className={navBarState[4]?"current-menu-item outline-round":"outline-round"} onClick={()=>{navBarAction([false,false,false,false,true])}}>{t('COMMON_LETSGO',{ns:"common"})}</Link>
                             
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
                </div>
                {/*home process section html end*/}
                {/*home about section html start*/}
                <div className="about-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="home-about-content">
                            <div className="sub-title">{t('INTRO_INTRO')}</div>
                            <h2 className="section-title">{t('INTRO_TITLE')}?</h2>
                            <p><b>D.event</b>, {t('INTRO_P1')} <b>{t("COMMON_WEDDING",{ns:"common"})}</b> {t('INTRO_YOUR')} <b>{t("COMMON_BIRTHDAY",{ns:"common"})}</b> {t('INTRO_P2')} <b>{t("COMMON_EVENT",{ns:"common"})}s</b>.</p>
                            <div className="about-list">
                                <ul>
                                    <li><i aria-hidden="true" className="fas fa-check"></i>{t('INTRO_STEP1')}.</li>
                                    <li><i aria-hidden="true" className="fas fa-check"></i>{t('INTRO_STEP2')}.</li>
                                    <li><i aria-hidden="true" className="fas fa-check"></i>{t('INTRO_STEP3')}.</li>
                                    <li><i aria-hidden="true" className="fas fa-check"></i>{t('INTRO_STEP4')}.</li>
                                </ul>
                            </div>
                            <div className="app-rating d-flex flex-wrap">
                                <div className="app-rating-item d-flex align-items-center">
                                    <div className="rating-point">
                                        <h2>4.0</h2>
                                    </div>
                                    <div className="rating-wrap">
                                        <div className="rating-start-inner">
                                        <div className="rating-start">
                                            <span style={{width: '80%'}}></span>
                                        </div>
                                        </div>
                                        <div className="rating-review">5,000 Facebook</div>
                                    </div>
                                </div>
                                <div className="app-rating-item d-flex">
                                    <div className="rating-point">
                                        <h2>3.4</h2>
                                    </div>
                                    <div className="rating-wrap">
                                        <div className="rating-start-inner">
                                        <div className="rating-start">
                                            <span style={{width: '70%'}}></span>
                                        </div>
                                        </div>
                                        <div className="rating-review">3,000 Instagram</div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <figure className="about-img-left">
                            <img src="assets/images/img2.png" alt=""/>
                            </figure>
                        </div>
                    </div>
                </div>
                <div className="about-left-shape"></div>
                </div>
                                    {/*<!-- home testimonial section html end -->*/}
                {/*<!-- home blog section html start -->*/}
                <section className="app-section">
                    <div className="container">
                        <div className="section-heading text-center">
                            <div className="row">
                                <div className="col-lg-8 offset-lg-2">
                                <div className="sub-title">{t('CHECK_CHECKOUT')}</div>
                                    <h2 className="section-title">{t('CHECK_P1')}</h2>
                                    <p>{t('CHECK_P2')}.<br/>{t('CHECK_P3')}.</p> 
                                </div>
                            </div>
                        </div>
                        <div className="app-slider-inner">
                            <div className="app-slider">
                                <div className="app-slider-item">
                                    <img src="assets/images/app3.png" alt=""/>
                                </div>
                                <div className="app-slider-item">
                                    <img src="assets/images/app2.png" alt=""/>
                                </div>
                                <div className="app-slider-item">
                                    <img src="assets/images/app1.png" alt=""/>
                                </div>
                                <div className="app-slider-item">
                                    <img src="assets/images/app4.png" alt=""/>
                                </div>
                                <div className="app-slider-item">
                                    <img src="assets/images/app5.png" alt=""/>
                                </div>
                                <div className="app-slider-item">
                                    <img src="assets/images/app6.png" alt=""/>
                                </div>
                                <div className="app-slider-item">
                                    <img src="assets/images/app7.png" alt=""/>
                                </div>
                                <div className="app-slider-item">
                                    <img src="assets/images/app8.png" alt=""/>
                                </div>
                                <div className="app-slider-item">
                                    <img src="assets/images/app9.png" alt=""/>
                                </div>
                                <div className="app-slider-item">
                                    <img src="assets/images/app10.png" alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="app-center-shape"></div>
                </section>
                {/*home about section html end*/}
                {/*home callback section html start*/}
                <DownloadApp isMarginBottom={false}/>
                {/*<!-- home callback section html end -->*/}
                {/*<!-- home pricing table section html start -->*/}
                <section className="pricing-table-section bg-light-grey">
                <div className="top-price-shape">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                        <path className="elementor-shape-fill" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
                        c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
                        c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"></path>
                    </svg>
                </div>
                <div className="container">
                    <div className="section-heading text-center">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                            <div className="sub-title">{t("PRICING_PLAN")}</div>
                            <h2 className="section-title">{t("PRICING_TITLE")}</h2>
                            <p>{t("PRICING_DETAIL")}.</p>
                            </div>
                        </div>
                    </div>
                    <div className="pricing-table-inner">
                        <div className="row g-0 align-items-center">
                            <div className="col-sm-4">
                            <div className="pricing-item">
                                <div className="pricing-head">
                                    <h4>{t("PRICING_STANDART")}</h4>
                                    <span className="pricing-icon">
                                        <i aria-hidden="true" className="fab fa-telegram-plane"></i>
                                    </span>
                                    <h2>{t("PRICING_STAND_FREE")}</h2>
                                    <h5>{t("PRICING_STAND_ONEMONTH")}</h5>
                                </div>
                                <div className="pricing-list">
                                    <ul>
                                        <li>{t("PRICING_STAND_P1")} <b>{t("COMMON_WEDDING",{ns:"common"})}</b></li>    
                                        <li>{t("PRICING_STAND_P2")} </li>
                                        <li>{t("PRICING_STAND_P3")}</li>
                                        <li>{t("PRICING_STAND_P4")}</li>
                                        <li>{t("PRICING_STAND_P5")}</li>
                                        <li>{t("PRICING_STAND_P6")}</li>
                                    </ul>
                                </div>
                                
                                <Link to="contact_us"  className={navBarState[4]?"current-menu-item outline-round":"outline-round"} onClick={()=>{navBarAction([false,false,false,false,true])}}>{t("COMMON_CONTACTUS",{ns:"common"})}</Link>
                                
                                
                            </div>
                            </div>
                            <div className="col-sm-4">
                            <div className="pricing-item table-active">
                                <div className="pricing-head">
                                    <h4>Premium</h4>
                                    <span className="pricing-icon">
                                        <i aria-hidden="true" className="fas fa-rocket"></i>
                                    </span>
                                    <h2>60.000 FCFA</h2>
                                    <h5>$90</h5>
                                </div>
                                <div className="pricing-list">
                                    <ul>
                                        <li>{t("PRICING_STAND_P1")} <b>{t("COMMON_WEDDING",{ns:"common"})} {t("AND",{ns:"common"})} {t("COMMON_BIRTHDAY",{ns:"common"})}</b></li>
                                        <li>{t("PRICING_PREM_P1")} </li>
                                        <li>D.Event {t("PRICING_PREM_P2")}</li>
                                        <li>D.Event {t("PRICING_PREM_P3")}</li>
                                        <li>D.event {t("PRICING_PREM_P4")} <b>{t("COMMON_WEDDING",{ns:"common"})}/{t("COMMON_BIRTHDAY",{ns:"common"})}</b></li>
                                    </ul>
                                </div>
                                <Link to="contact_us"  className={navBarState[4]?"current-menu-item outline-round":"outline-round"} onClick={()=>{navBarAction([false,false,false,false,true])}}>{t("COMMON_CONTACTUS",{ns:"common"})}</Link>
                            </div>
                            </div>
                            <div className="col-sm-4">
                            <div className="pricing-item">
                                <div className="pricing-head">
                                    <h4>{t("PRICING_COORP")}</h4>
                                    <span className="pricing-icon">
                                        <i aria-hidden="true" className="fas fa-plane"></i>
                                    </span>
                                    <h2>150.000 FCFA</h2>
                                    <h5>$130</h5>
                                </div>
                                <div className="pricing-list">
                                    <ul>
                                        <li>{t("PRICING_STAND_P1")} <b>{t("PRICING_COORP_P1")}</b></li>
                                        <li>{t("PRICING_COORP_P2")} </li>
                                        <li>{t("PRICING_COORP_P3")}</li>
                                        <li>{t("PRICING_COORP_P4")}</li>
                                        <li>{t("PRICING_COORP_P5")}</li>
                                    </ul>
                                </div>
                                <Link to="contact_us"  className={navBarState[4]?"current-menu-item outline-round":"outline-round"} onClick={()=>{navBarAction([false,false,false,false,true])}}>{t("COMMON_CONTACTUS",{ns:"common"})}</Link>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="price-right-shape"></div>
                <div className="bottom-price-shape">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                        <path className="elementor-shape-fill" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
                        c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
                        c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"></path>
                    </svg>
                </div>
                </section>
                {/*<!-- home team section html end -->*/}
                {/*<!-- home support section html start -->*/}
                {/*<!-- home subscribe section html start -->*/}
                <section className="subscribe-section-blue subscribe-section">
                <div className="subscribe-shape">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                        <path className="elementor-shape-fill" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
                        c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
                        c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"></path>
                    </svg>
                </div>
                <div className="overlay"></div>
                <div className="subscribe-outer">
                    <div className="container">
                        <div className="subscribe-inner">
                            <div className="row align-items-center">
                            <div className="col-md-7">
                                <div className="subscribe-content">
                                    <div className="sub-title">{t("ACTION_TITLE")}</div>
                                    <h2 className="section-title">{t("ACTION_P1")}</h2>
                                    <p>{t("ACTION_P2")}.</p>                                                                                                     
                                </div>
                            </div>
                            <div className="col-md-5 d-flex align-items-end">
                                <figure className="subscribe-image">
                                    <video width="100%" height="100%" controls>
                                        <source src="assets/video/spot.mp4" type="video/mp4" />
                                    </video>
                                </figure>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                </section>
                {/*<!-- home counter section html end -->*/}
                {/*<!-- home blog section html start -->*/}
                <section className="blog-section">
                <div className="container">
                    <div className="section-heading text-center">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                            <div className="sub-title">{t("CHOOSE_TITLE")}</div>
                            <h2 className="section-title">{t("CHOOSE_P1")}</h2>
                            <p>{t("CHOOSE_P2")}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-4">
                            <article className="post">
                            <figure className="feature-image">
                                <a href="#">
                                    <img src="assets/images/img7.png" alt=""/>
                                </a>
                            </figure>
                            <div className="entry-content">
                                <h4>
                                    <a href="#">{t("CHOOSE_CARD1_TITLE")}</a>
                                </h4>
                                <p>{t("CHOOSE_CARD1_P")}</p>
                            </div>
                            </article>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <article className="post">
                            <figure className="feature-image">
                                <a href="#">
                                    <img src="assets/images/img8.png" alt=""/>
                                </a>
                            </figure>
                            <div className="entry-content">
                                <h4>
                                    <a href="#">{t("CHOOSE_CARD2_TITLE")}</a>
                                </h4>
                                <p>{t("CHOOSE_CARD2_P")}.</p>
                            </div>
                            </article>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <article className="post">
                            <figure className="feature-image">
                                <a href="#">
                                    <img src="assets/images/img9.png" alt=""/>
                                </a>
                            </figure>
                            <div className="entry-content">
                                <h4>
                                    <a href="#">{t("CHOOSE_CARD3_TITLE")}</a>
                                </h4>
                                <p>{t("CHOOSE_CARD3_P")}.</p>
                            </div>
                            </article>
                        </div>
                    </div>
                </div>
                <div className="blog-left-shape"></div>
                </section>
                {/*<!-- home blog section html end -->*/}
            </main>
        </Fragment>
    );
}

export default WelcomePage;