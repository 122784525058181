import React, { useEffect,Fragment,useState } from "react";
import CardItem from "../components/card_item";
import DownloadApp from "../components/download_app";
import CardsVM from "../view_model/cards_vm";
import { FormWeddingCardModal } from "../components/form_wedding_card_modal";
import { useTranslation } from "react-i18next";

const WeddingCards = ()=>{

    const listCards = CardsVM((state)=>state.listCards);
    const fetchCardData = CardsVM((state)=>state.fetchListCards);
    const filterData = CardsVM((state)=>state.filterCards);
    const [show, setShow] = useState(false);
    const {t} = useTranslation(['wedding_card','common']);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(()=>{
        fetchCardData();
    },[]);

    return (
        <Fragment>
            <main id="content" className="site-main">
                    {/*<!-- Inner Banner html start-->*/}
                    <section className="inner-banner-wrap">
                        <div className="inner-baner-container">
                        <div className="overlay-image"></div>
                        <div className="bottom-shape">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                                <path className="elementor-shape-fill" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
                                c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
                                c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"></path>
                            </svg>
                        </div>
                        <div className="container">
                            <div className="col-lg-12">
                                <div className="inner-banner-content">
                                    <h2 className="inner-title" >{t('HEADER_TITLE')}<b onClick={handleShow}>?</b></h2><br/>
                                    <h4 className="inner-title">{t('HEADER_TITLE_P')}</h4><br/>                                                                                                       
                                </div>
                                <div className="row justify-content-md-center ">
                                    <div className="col col-12 col-md-auto smallMarginTop" ><a href="assets/apk/devent.apk" className="button-round" target="_blank">{t('COMMON_DOWNLOAD_M',{ns:"common"})}</a></div>

                                    {/*<div className="col col-12 col-md-auto smallMarginTop" ><a href="#" className="outline-round outline-round-white" onClick={handleShow}>Create template</a></div>*/}
                                    <FormWeddingCardModal show={show} handleClose={handleClose}/>
                                </div>    
                            </div>
                        </div>
                        </div>                        
                    </section>
                    
                    {/*<!-- Inner Banner html end-->*/}
                    {/*<!-- Team html start -->*/}
                    <div className="team-page-section">
                        <div className="team-page-inner">
                            <div className="container">
                                <div className="row align-items-end">
                                    {/*<!-- pagination and card filter html start-->*/}
                                    <div className="col col-md-4 smallMarginBot">
                                        <form className="search-form">
                                            <input type="text" name="search" placeholder={t('COMMON_SEARCH',{ns:"common"})} onChange={(e)=>{
                                                filterData(e.target.value)
                                            }}/>
                                            <button className="search-btn">
                                                <i className="fas fa-search"></i>
                                            </button>
                                        </form>                                    
                                    </div>
                                    {/*<div className="col col-md-4 col-12 smallMarginBot">
                                        <div className="post-navigation-wrap">
                                            <nav>
                                                <ul className="pagination">
                                                    <li>
                                                        <a href="#">
                                                        <i className="fas fa-arrow-left"></i>
                                                        </a>
                                                    </li>
                                                    <li className="active"><a href="#">1</a></li>
                                                    <li><a href="#">..</a></li>                                    
                                                    <li>
                                                        <a href="#">
                                                        <i className="fas fa-arrow-right"></i>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>*/}
                                </div>  
                                <div className="row ">
                                    {
                                        listCards.map!=null&&listCards.map((element,index)=>{ return <CardItem key={index} cardData={element} />})
                                    }
                                    {   
                                        listCards.map!=null && listCards.length==0 &&<div className="container"><div className="row justify-content-md-center"><div className="col col-2">{t('NO_DATA',{ns:"common"})}</div></div></div> 
                                    }

                                    {   
                                        listCards.map==null&&<div className="container"><div className="row">{t('COMMON_NO_INTERNET',{ns:"common"})}</div></div> 
                                    }

                                </div>
                                <div className="row justify-content-end" >
                                    {/*<!-- pagination html start-->*/}
                                   {/* <div className="col col-md-auto smallMarginBot" >
                                        <div className="post-navigation-wrap">
                                            <nav>
                                                <ul className="pagination">
                                                    <li>
                                                        <a href="#">
                                                        <i className="fas fa-arrow-left"></i>
                                                        </a>
                                                    </li>
                                                    <li className="active"><a href="#">1</a></li>
                                                    <li><a href="#">..</a></li>                                    
                                                    <li>
                                                        <a href="#">
                                                        <i className="fas fa-arrow-right"></i>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                {/*<!-- Team html end -->*/}
                {/*<!-- download callback section html start -->*/}
                <section className="blog-section">
                    <DownloadApp isOverlay={true}/>
                </section>                
            </main>            
        </Fragment>
    );
}

export default WeddingCards;

